exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-en-js": () => import("./../../../src/pages/about.en.js" /* webpackChunkName: "component---src-pages-about-en-js" */),
  "component---src-pages-business-support-en-js": () => import("./../../../src/pages/business/support.en.js" /* webpackChunkName: "component---src-pages-business-support-en-js" */),
  "component---src-pages-careers-index-en-js": () => import("./../../../src/pages/careers/index.en.js" /* webpackChunkName: "component---src-pages-careers-index-en-js" */),
  "component---src-pages-contact-en-js": () => import("./../../../src/pages/contact.en.js" /* webpackChunkName: "component---src-pages-contact-en-js" */),
  "component---src-pages-faq-de-js": () => import("./../../../src/pages/faq.de.js" /* webpackChunkName: "component---src-pages-faq-de-js" */),
  "component---src-pages-faq-en-js": () => import("./../../../src/pages/faq.en.js" /* webpackChunkName: "component---src-pages-faq-en-js" */),
  "component---src-pages-faq-fi-js": () => import("./../../../src/pages/faq.fi.js" /* webpackChunkName: "component---src-pages-faq-fi-js" */),
  "component---src-pages-faq-sv-js": () => import("./../../../src/pages/faq.sv.js" /* webpackChunkName: "component---src-pages-faq-sv-js" */),
  "component---src-pages-products-nigeria-js": () => import("./../../../src/pages/products/nigeria.js" /* webpackChunkName: "component---src-pages-products-nigeria-js" */),
  "component---src-pages-products-sirupay-demo-en-js": () => import("./../../../src/pages/products/sirupay/demo.en.js" /* webpackChunkName: "component---src-pages-products-sirupay-demo-en-js" */),
  "component---src-pages-products-sirupay-index-en-js": () => import("./../../../src/pages/products/sirupay/index.en.js" /* webpackChunkName: "component---src-pages-products-sirupay-index-en-js" */),
  "component---src-pages-products-siruqr-en-js": () => import("./../../../src/pages/products/siruqr.en.js" /* webpackChunkName: "component---src-pages-products-siruqr-en-js" */),
  "component---src-pages-products-sirusms-js": () => import("./../../../src/pages/products/sirusms.js" /* webpackChunkName: "component---src-pages-products-sirusms-js" */),
  "component---src-pages-starteco-en-js": () => import("./../../../src/pages/starteco.en.js" /* webpackChunkName: "component---src-pages-starteco-en-js" */),
  "component---src-pages-starteco-sv-js": () => import("./../../../src/pages/starteco.sv.js" /* webpackChunkName: "component---src-pages-starteco-sv-js" */),
  "component---src-pages-startecog-en-js": () => import("./../../../src/pages/startecog.en.js" /* webpackChunkName: "component---src-pages-startecog-en-js" */),
  "component---src-pages-startecog-sv-js": () => import("./../../../src/pages/startecog.sv.js" /* webpackChunkName: "component---src-pages-startecog-sv-js" */),
  "component---src-pages-startecoprereg-en-js": () => import("./../../../src/pages/startecoprereg.en.js" /* webpackChunkName: "component---src-pages-startecoprereg-en-js" */),
  "component---src-pages-startecoprereg-sv-js": () => import("./../../../src/pages/startecoprereg.sv.js" /* webpackChunkName: "component---src-pages-startecoprereg-sv-js" */),
  "component---src-templates-sanity-page-js": () => import("./../../../src/templates/SanityPage.js" /* webpackChunkName: "component---src-templates-sanity-page-js" */)
}

