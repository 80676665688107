import React from 'react'

/**
 * A helper context to provide the current page props to all components
 *
 * @type {React.Context<unknown>}
 */
export const PagePropsContext = React.createContext()

export const PagePropsProvider = ({ children, value }) => (
    <PagePropsContext.Provider value={{ ...value }}>{children}</PagePropsContext.Provider>
)

export default PagePropsContext
