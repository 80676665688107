const messages_fi = {
    login: 'Kirjaudu',
    products: 'Tuotteet',
    about: 'Meistä',
    contact_us: 'Ota yhteyttä',
    support: 'Tuki',
    'contactform.accept_marketing': 'Haluan vastaanottaa markkinointiviestejä ja ohjeita palvelun käytöstä',
    'contactform.name': 'Nimi',
    'contactform.phone': 'Puhelin',
    'contactform.email': 'Sähköposti',
    'contactform.message': 'Viesti',
    'contactform.skynet_pot': 'Älä täytä tätä jos olet ihminen:',
    'contactform.submit': 'Lähetä',
    'contactform.error': 'Voi ei, lomakkeen lähetyksessä tapahtui virhe! Yritä myöhemmin uudelleen.',
    'contactform.thankyou.h': 'Kiitos!',
    'contactform.thankyou.p': 'Saimme viestisi ja katsomme sitä pian.',
    'cookieconsent.heading': 'Tietoa sivustolla käytetyistä evästeistä',
    'cookieconsent.content.p':
        'Verkkosivustomme käyttää ensimmäisen ja kolmannen osapuolen evästeitä asiakaskokemuksen parantamiseksi. Käytämme analytiikkaevästeitä vain, jos hyväksyt ne. Mikäli kieltäydyt analytiikkaevästeistä, saatamme silti käyttää välttämättömiä ensimmäisen osapuolen evästeitä jos jatkat sivuston selaamista. Evästekäytäntömme löytyy <a>täältä</a>.',
    'cookieconsent.decline': 'Hylkää',
    'cookieconsent.accept': 'Hyväksy',
    'footer.logo_punchline': 'Kuten sovellus, mutta parempi.',
    'footer.company': 'Yhtiö',
    'footer.company.careers': 'Työpaikat',
    'footer.learn_more': 'Lue lisää',
    'footer.learn_more.faq': 'UKK',
    'footer.learn_more.terms_of_use': 'Käyttöehdot',
    'footer.learn_more.privacy_policy': 'Yksityisyysseloste',
    'footer.learn_more.cookie_policy': 'Evästekäytäntö',
    'footer.merchant_support': 'Kauppiaan tuki',
    'footer.developers': 'Kehittäjät',
}

export default messages_fi
