import React from 'react'
import GlobalStyles from './GlobalStyles'
import Typography from './Typography'
import 'normalize.css'
import './custom-bootstrap.scss'

const BaseStyles = () => (
    <>
        <GlobalStyles />
        <Typography />
    </>
)

export default BaseStyles
