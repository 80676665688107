import React from 'react'
import { useLocation } from '@reach/router'
import { graphql, useStaticQuery } from 'gatsby'
import { getCurrentLangKey } from 'ptz-i18n'
import { IntlProvider } from 'react-intl'
import messages_en from '../data/translations/en'
import messages_fi from '../data/translations/fi'
import messages_sv from '../data/translations/sv'
import messages_de from '../data/translations/de'

export const languageMessagesMap = {
    en: messages_en,
    fi: messages_fi,
    sv: messages_sv,
    de: messages_de,
}

/**
 * Gatsby Browser API: wrapPageElement
 *
 * Allow a plugin to wrap the page element.
 *
 * @param children
 * @returns {JSX.Element}
 */
const BaseIntlProvider = ({ children }) => {
    const location = useLocation()

    const data = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        languages {
                            defaultLangKey
                            langs
                        }
                    }
                }
            }
        `
    )

    const url = location.pathname
    const { langs, defaultLangKey } = data.site.siteMetadata.languages
    const currentLangKey = getCurrentLangKey(langs, defaultLangKey, url)

    return (
        <IntlProvider locale={currentLangKey} messages={languageMessagesMap[currentLangKey]}>
            {children}
        </IntlProvider>
    )
}

export default BaseIntlProvider
