const messages_de = {
    login: 'Anmelden',
    products: 'Produkte',
    about: 'Über uns',
    contact_us: 'Kontaktiere uns',
    support: 'Unterstützung',
    'contactform.accept_marketing': 'Ich möchte Marketing-Nachrichten und Tipps erhalten, wie ich den Service nutze',
    'contactform.name': 'Name',
    'contactform.phone': 'Telefon',
    'contactform.email': 'Email',
    'contactform.message': 'Nachricht',
    'contactform.skynet_pot': 'Füllen Sie dies nicht aus, wenn Sie ein Mensch sind:',
    'contactform.submit': 'Senden',
    'contactform.error':
        'Oh nein, beim Absenden dieses Formulars ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal!',
    'contactform.thankyou.h': 'Danke!',
    'contactform.thankyou.p': 'Wir haben Ihre Nachricht erhalten und werden uns diese bald ansehen.',
    'cookieconsent.heading': 'Sie kontrollieren Ihre Daten',
    'cookieconsent.content.p':
        'Unsere Website verwendet Cookies von Erst- und Drittanbietern, die die Funktion der Website unterstützen, damit wir Ihnen die bestmögliche Benutzererfahrung bieten können. Wir verwenden analytische Cookies nur, wenn Sie dem zustimmen, indem Sie auf Akzeptieren klicken. Indem Sie auf „Ablehnen“ klicken, lehnen Sie die Verwendung von analytischen Cookies ab, aber wir können dennoch notwendige First-Party-Cookies verwenden, wenn Sie weiterhin auf unserer Website surfen. Unsere Cookie-Richtlinie finden Sie <a>hier</a>.',
    'cookieconsent.decline': 'Ablehnen',
    'cookieconsent.accept': 'Annehmen',
    'footer.logo_punchline': 'Es ist wie eine App, aber besser.',
    'footer.company': 'Gesellschaft',
    'footer.company.careers': 'Karriere',
    'footer.learn_more': 'Mehr erfahren',
    'footer.learn_more.faq': 'FAQ',
    'footer.learn_more.terms_of_use': 'Nutzungsbedingungen',
    'footer.learn_more.privacy_policy': 'Datenschutz-Bestimmungen',
    'footer.learn_more.cookie_policy': 'Cookie-Richtlinie',
    'footer.merchant_support': 'Händlerunterstützung',
    'footer.developers': 'Entwickler',
}

export default messages_de
